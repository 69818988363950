import React, { useState } from 'react';
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink as BSNavLink } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import bn from '../../utils/bemnames';
import {
  MdDashboard, MdFindInPage,
  MdHistory, MdInsertChart,
  MdMenu, MdNewReleases,
  MdNotifications,
} from 'react-icons/md';
import { Route } from 'react-router';
import CampaignAnalyticsPage from '../../pages/CampaignAnalytics/CampaignAnalyticsPage';
import AutoCpaLogsPage from '../../pages/AutoCpaLogs/AutoCpaLogsPage';
import CampaignsAlertsPage from '../../pages/CampaignsAlerts/CampaignsAlertsPage';
import OffersPage from '../../pages/Offers/OffersPage';


const bem = bn.create('sidebar');

const navItems = [
  { to: '/', name: 'dashboard', exact: true, Icon: MdDashboard },
  { to: '/campaigns/analytics', name: 'Analytics', exact: true, Icon: MdInsertChart },
  { to: '/campaigns/alerts', name: 'Alerts', exact: true, Icon: MdNotifications },
  { to: '/eva/alerts', name: 'Eva', exact: true, Icon: MdNewReleases },
  { to: '/autocpa/logs', name: 'Autocpa logs', exact: true, Icon: MdFindInPage },
  { to: '/campaigns/smartbids/history', name: 'Smartbid history', exact: true, Icon: MdHistory },
  { to: '/offers', name: 'Offers', exact: true, Icon: MdDashboard },
];

export default function Navigation(args) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar expand='lg' style={{ backgroundColor: '#6a82fb' }} {...args}>
        <NavbarBrand className={'text-white'} href='/'>Admin Panel</NavbarBrand>
        <NavbarToggler onClick={toggle}>
          <MdMenu className={'text-white'} />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar>
          <Nav className='me-auto' navbar>
            {navItems.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink style={{color: '#fff'}}
                  id={`navItem-${name}-${index}`}
                  className='text-uppercase pl-2'
                  tag={NavLink}
                  to={to}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className=''>{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}